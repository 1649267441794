import React from 'react';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Link as RouterLink } from 'react-router-dom';

export const HabourMasterHeader: React.FC = () => (
  <h1>
    <Alert severity="warning" sx={{ mb: 2 }}>
      <AlertTitle>The Harbour Master is going through some changes</AlertTitle>
      <p>
        Also, remember this is an experimental area where <u>data can be stale or inaccurate</u>.
      </p>
      <p>
        <strong>Please always only use the Nym Wallet desktop application to get accurate data.</strong>
      </p>
    </Alert>
    <Link component={RouterLink} to="/">
      🚢 Nym Harbour Master
    </Link>
  </h1>
);
