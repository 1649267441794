import React from 'react';
import Box from '@mui/material/Box';
import { api } from './api';
import { SummaryCharts } from './SummaryCharts';
import { MixnetStakingCharts, MixnetStatsCharts } from './MixnetStatsCharts';

export const Charts: React.FC = () => {
  const [summaryHistory, setSummaryHistory] = React.useState<any>(null);
  const [mixnetStats, setMixnetStats] = React.useState<any>(null);

  const refresh = async () => {
    try {
      const newSummaryHistory = await api.summaryHistory();
      setSummaryHistory(newSummaryHistory);
    } catch (e) {
      console.error('Failed to get summary history', e);
    }
    try {
      const newMixnetStats = await api.mixnodeStats();
      setMixnetStats(newMixnetStats);
    } catch (e) {
      console.error('Failed to get stats', e);
    }
  };

  React.useEffect(() => {
    refresh();
    const timer = setInterval(refresh, 1000 * 60 * 5);
    return () => clearInterval(timer);
  }, []);

  return (
    <Box>
      <Box pb={4}>
        <h2>🏴‍☠️ because pirates ❤️ charts 🗺️</h2>
      </Box>

      <Box mb={4} pt={5} pb={3} pl={3} sx={{ border: '2px solid #eee', borderRadius: 3, width: 1250 }}>
        <SummaryCharts summaryHistory={summaryHistory} />
      </Box>
      <Box mb={4} pt={5} pb={3} pl={3} sx={{ border: '2px solid #eee', borderRadius: 3, width: 1250 }}>
        <MixnetStatsCharts mixnetStats={mixnetStats} />
      </Box>
      <Box mb={4} pt={5} pb={3} pl={3} sx={{ border: '2px solid #eee', borderRadius: 3, width: 1250 }}>
        <MixnetStakingCharts mixnetStats={mixnetStats} height={300} />
      </Box>
    </Box>
  );
};
