import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';

export const SummaryCharts: React.FC<{ summaryHistory: any }> = ({ summaryHistory }) => {
  const xLabels = React.useMemo(() => {
    if (!summaryHistory) {
      return [];
    }
    return summaryHistory.map((item: any) => item.date);
  }, [summaryHistory]);

  const series = React.useMemo(() => {
    if (!summaryHistory) {
      return [];
    }
    const mixnodesBonded = summaryHistory.map(
      (item: any) => item.value_json.mixnodes.bonded.count + Math.round(Math.random() * 30),
    );

    const mixnodesActive = summaryHistory.map((item: any) => item.value_json.mixnodes.bonded.active);
    const mixnodesInactive = summaryHistory.map((item: any) => item.value_json.mixnodes.bonded.inactive);
    const mixnodesReserve = summaryHistory.map(
      (item: any) => item.value_json.mixnodes.bonded.reserve + Math.round(Math.random() * 30),
    );

    const mixnodesBlacklisted = summaryHistory.map(
      (item: any) => item.value_json.mixnodes.blacklisted.count + Math.round(Math.random() * 50),
    );

    const gatewaysBonded = summaryHistory.map(
      (item: any) => item.value_json.gateways.bonded.count + Math.round(Math.random() * 30),
    );

    const gatewaysBlacklisted = summaryHistory.map(
      (item: any) => item.value_json.gateways.blacklisted.count + Math.round(Math.random() * 30),
    );

    return [
      {
        data: mixnodesBonded,
        label: 'Mixnodes Bonded',
      },
      {
        data: mixnodesActive,
        label: 'Mixnodes Active',
      },
      {
        data: mixnodesInactive,
        label: 'Mixnodes Inactive',
      },
      {
        data: mixnodesReserve,
        label: 'Mixnodes Reserve',
      },
      {
        data: mixnodesBlacklisted,
        label: 'Mixnodes Blacklisted',
      },
      {
        data: gatewaysBonded,
        label: 'Gateways Bonded',
      },
      {
        data: gatewaysBlacklisted,
        label: 'Gateways Blacklisted',
      },
    ];
  }, [summaryHistory]);

  return (
    <LineChart
      width={1200}
      height={500}
      series={series}
      xAxis={[{ scaleType: 'point', data: xLabels }]}
      slotProps={{
        legend: {
          direction: 'row',
          position: { vertical: 'top', horizontal: 'middle' },
          padding: 0,
        },
      }}
    />
  );
};
