/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import Box from '@mui/material/Box';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { api } from './api';
import { type ServiceWithDates } from './types';

export const Services: React.FC = () => {
  const [services, setServices] = React.useState<ServiceWithDates[]>([]);
  const [lastUpdated, setLastUpdated] = React.useState<Date>();
  const [busy, setBusy] = React.useState(false);
  const [busyMessage, setBusyMessage] = React.useState<string>();

  const refreshServices = async () => {
    try {
      const newServices = await api.services();
      setServices(newServices.items);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error getting services', e);
    }
  };

  const refresh = async () => {
    try {
      setBusy(true);
      setBusyMessage('Getting latest data...');
      await refreshServices();
      setLastUpdated(new Date());
      setBusyMessage('Finishing refresh...');
    } finally {
      setBusy(false);
    }
  };

  React.useEffect(() => {
    refresh();
    const timer = setInterval(refresh, 1000 * 60 * 5);
    return () => clearInterval(timer);
  }, []);

  const columns = React.useMemo<MRT_ColumnDef<ServiceWithDates>[]>(
    () => [
      {
        header: 'Identity Key',
        accessorKey: 'gateway_identity_key',
        Cell: ({ cell }) => (
          <Link component={RouterLink} to={`/gateway/${cell.getValue<string>()}`}>
            {cell.getValue<string>()}
          </Link>
        ),
      },
      {
        header: 'Hostname',
        accessorKey: 'hostname',
      },
      {
        header: 'IP Address',
        accessorKey: 'ip_address',
      },
      {
        header: 'Mixnet Client Websocket Port',
        accessorKey: 'mixnet_websockets.ws_port',
      },
      {
        header: 'Mixnet Client Secure Websocket Port',
        accessorKey: 'mixnet_websockets.wss_port',
      },
      {
        header: 'Last successful ping',
        accessorKey: 'last_successful_ping_utc',
        Cell: ({ cell }) => (
          <>
            <strong>
              {formatDistanceToNow(cell.row.original.last_successful_ping_utc, {
                addSuffix: true,
              })}
            </strong>
            <br />
            {cell.row.original.last_successful_ping_utc.toISOString()}
          </>
        ),
      },
      {
        header: 'SOCKS5 Service Provider Client Ic',
        accessorKey: 'service_provider_client_id',
        width: 150,
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    initialState: {
      pagination: { pageSize: 25, pageIndex: 0 },
      density: 'compact',
      showColumnFilters: true,
      sorting: [
        {
          id: 'probeScoreCalculated',
          desc: true,
        },
      ],
    },
    data: services,
    enableRowSelection: true, // enable some features
    enableColumnOrdering: false, // enable a feature for all columns
    enableGlobalFilter: false, // turn off a feature
  });

  if (!lastUpdated) {
    return null;
  }

  return (
    <Box mt={5} py={2}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          {busy ? (
            <Typography>{busyMessage || 'Refreshing...'}</Typography>
          ) : (
            <Button variant="outlined" onClick={refresh}>
              Refresh
            </Button>
          )}
        </Box>
        {lastUpdated && (
          <Stack direction="row" spacing={2}>
            <Typography>Last updated</Typography>
            <Typography>
              <strong>
                {formatDistanceToNow(lastUpdated, {
                  addSuffix: true,
                })}
              </strong>
              <br />
              {lastUpdated.toISOString()}
            </Typography>
          </Stack>
        )}
      </Box>
      <MaterialReactTable table={table} />
    </Box>
  );
};
