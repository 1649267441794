/* eslint-disable no-param-reassign */
// const API_BASE = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'https://harbourmaster.nymtech.net';
import { getConfigScore, testSemVer } from './scores/configScore';
import { getProbeScore } from './scores/probeScore';
import {
  GatewayCoreResponse,
  GatewayScore,
  GatewayStatusAvgUptimeResponse,
  GatewayTestResponse,
  PagedResult,
  ServiceWithDates,
} from './types';

const API_BASE = 'https://harbourmaster.nymtech.net';

const NYM_API_BASE = 'https://validator.nymtech.net';

export interface DirectoryGatewayProbeOutcomeWGV2 {
  can_handshake_v4: boolean;
  can_handshake_v6: boolean;
  can_register_v4: boolean;
  can_register: boolean;
  can_resolve_dns_v4: boolean;
  can_resolve_dns_v6: boolean;
  ping_hosts_performance_v4: number;
  ping_hosts_performance_v6: number;
  ping_ips_performance_v4: number;
  ping_ips_performance_v6: number;
}

export function isDirectoryGatewayProbeOutcomeWGV2(obj: any): obj is DirectoryGatewayProbeOutcomeWGV2 {
  return obj?.can_handshake_v4 !== undefined;
}

const mapGatewayItem = (item: any) => {
  item.config_score_calculated = 0;

  const configScore = getConfigScore(item);
  const probeScore = getProbeScore(item);

  const networkProbeResult = probeScore.probeScoreItems;

  if (
    networkProbeResult?.wg &&
    isDirectoryGatewayProbeOutcomeWGV2(networkProbeResult?.wg) &&
    networkProbeResult?.wg?.can_handshake === undefined
  ) {
    networkProbeResult.wg = {
      ...networkProbeResult.wg,
      can_handshake: networkProbeResult.wg.can_handshake_v4,
      can_resolve_dns: networkProbeResult.wg.can_resolve_dns_v4,
      ping_hosts_performance: networkProbeResult.wg.ping_hosts_performance_v4,
      ping_ips_performance: networkProbeResult.wg.ping_ips_performance_v4,
    };
  }

  const probeScoreItems = item.last_probe_result?.outcome;

  const gatewayScore: GatewayScore = {
    ...configScore,
    ...probeScore,
  };

  item.gatewayScore = gatewayScore;

  item.config_score_calculated = gatewayScore.configScore;
  item.probe_score_calculated = gatewayScore.probeScore;

  item.last_testrun_utc = new Date(item.last_testrun_utc);
  item.last_updated_utc = new Date(item.last_updated_utc);

  item.wg_performance = Math.round(
    ((probeScoreItems?.wg?.ping_hosts_performance || 0) * 100 +
      (probeScoreItems?.wg?.ping_ips_performance || 0) * 100) /
      2,
  );
};

const mapMixnodeItem = (item: any) => {
  item.last_updated_utc = new Date(item.last_updated_utc);

  const version = item.self_described?.self_described?.build_information?.build_version || '0.0.0';
  const binaryName = item.self_described?.self_described?.build_information?.binary_name;
  const acceptsTermsAndConditions = Boolean(
    item.self_described?.self_described?.auxiliary_details?.accepted_operator_terms_and_conditions,
  );

  item.config = {
    version,
    binaryName,
  };
  item.configScore = {
    acceptsTermsAndConditions,
    runsNymnode: binaryName === 'nym-node',
    minBuildVersion: testSemVer(version) && binaryName === 'nym-node',
  };
};

export const api = {
  mixFetchGateways: async (): Promise<PagedResult<ServiceWithDates>> => {
    const res = await fetch(`${API_BASE}/v2/services?size=200&entry=true&wss=true`);
    const page = await res.json();
    page.items.forEach((item: any) => {
      item.last_successful_ping_utc = new Date(item.last_successful_ping_utc);
      item.last_updated_utc = new Date(item.last_updated_utc);
    });
    return page;
  },
  services: async (): Promise<PagedResult<ServiceWithDates>> => {
    const res = await fetch(`${API_BASE}/v2/services?size=200`);
    const page = await res.json();
    page.items.forEach((item: any) => {
      item.last_successful_ping_utc = new Date(item.last_successful_ping_utc);
      item.last_updated_utc = new Date(item.last_updated_utc);
    });
    return page;
  },
  gateways: async (): Promise<any[]> => {
    let total = 0;
    const items: any[] = [];
    for (let pageNo = 0; pageNo < 20; pageNo += 1) {
      // eslint-disable-next-line no-await-in-loop
      const res = await fetch(`${API_BASE}/v2/gateways?page=${pageNo}&size=200`);
      // eslint-disable-next-line no-await-in-loop
      const page = await res.json();
      page.items.forEach(mapGatewayItem);
      total = page.total;
      items.push(...page.items);
      if (items.length >= total) {
        break;
      }
    }
    return items;
  },
  gateway: async (identityKey: string): Promise<any> => {
    const res = await fetch(`${API_BASE}/v2/gateways/${identityKey}`);
    if (res.status !== 200) {
      throw new Error('Unable to get gateway details');
    }
    const data = await res.json();
    mapGatewayItem(data);
    return data;
  },
  gatewayTestResults: async (identityKey: string, page: number = 0): Promise<GatewayTestResponse> => {
    const res = await fetch(
      `${NYM_API_BASE}/api/v1/status/gateways/unstable/${identityKey}/test-results?page=${page}&per_page=10`,
    );
    const data: GatewayTestResponse = await res.json();
    data.data.forEach((item: any) => {
      item.epoch = item.timestamp;
      item.timestamp = new Date(item.timestamp * 1000);
    });
    return data;
  },
  gatewayCoreCount: async (identityKey: string): Promise<GatewayCoreResponse> => {
    const res = await fetch(`${NYM_API_BASE}/api/v1/status/gateway/${identityKey}/core-status-count`);
    const data: GatewayCoreResponse = await res.json();
    return data;
  },
  gatewayAverageUptime: async (identityKey: string): Promise<GatewayStatusAvgUptimeResponse> => {
    const res = await fetch(`${NYM_API_BASE}/api/v1/status/gateway/${identityKey}/avg_uptime`);
    const data: GatewayStatusAvgUptimeResponse = await res.json();
    return data;
  },
  mixnodes: async (): Promise<any[]> => {
    let total = 0;
    const items: any[] = [];
    for (let pageNo = 0; pageNo < 20; pageNo += 1) {
      // eslint-disable-next-line no-await-in-loop
      const res = await fetch(`${API_BASE}/v2/mixnodes?page=${pageNo}&size=200`);
      // eslint-disable-next-line no-await-in-loop
      const page = await res.json();
      page.items.forEach(mapMixnodeItem);
      total = page.total;
      items.push(...page.items);
      if (items.length >= total) {
        break;
      }
    }
    return items;
  },
  mixnode: async (identityKey: string): Promise<any> => {
    const res = await fetch(`${API_BASE}/v2/mixnodes/${identityKey}`);
    const data = await res.json();
    mapMixnodeItem(data);
    return data;
  },
  mixnodeStats: async (): Promise<any> => {
    const res = await fetch(`${API_BASE}/v2/mixnodes/stats`);
    const data = await res.json();
    return data.slice(1, -2); // trim off first item, and last item (current day)
  },
  summary: async (): Promise<any> => {
    const res = await fetch(`${API_BASE}/v2/summary`);
    const data = await res.json();
    return data;
  },
  summaryHistory: async (): Promise<any> => {
    const res = await fetch(`${API_BASE}/v2/summary/history`);
    const data = await res.json();
    return data;
  },
};
