/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { MaterialReactTable, type MRT_ColumnDef, useMaterialReactTable } from 'material-react-table';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Badge from '@mui/material/Badge';
import { BarChart } from '@mui/x-charts/BarChart';
import semverCompare from 'semver/functions/compare-loose';
import { api } from './api';
import { HumanDateTime } from './HumanDateTime';
import { SummaryLine } from './SummaryLine';
import { configCategories, minNymNodeVersion } from './scores';

const CACHE_KEY = 'cache-mixnodes';

export const Mixnodes: React.FC = () => {
  const [mixnodes, setMixnodes] = React.useState<any[]>([]);
  const [mixnodeCount, setMixnodeCount] = React.useState<number>();
  const [lastUpdated, setLastUpdated] = React.useState<Date>();
  const [busy, setBusy] = React.useState(false);
  const [busyMessage, setBusyMessage] = React.useState<string>();
  const [summary, setSummary] = React.useState<any>(null);

  const refreshMixnodes = async () => {
    try {
      const items = await api.mixnodes();
      try {
        window.sessionStorage.setItem(CACHE_KEY, JSON.stringify(items));
      } catch (ex) {
        console.error('Failed to store cache for mixnodes in session storage', ex);
      }
      setMixnodes(items);
      setMixnodeCount(items.length);
      setBusyMessage('Mixnode data updated...');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error getting services', e);
    }
  };

  const refresh = async () => {
    try {
      setBusy(true);
      setBusyMessage('Getting latest data...');
      await Promise.all([refreshMixnodes()]);
      setLastUpdated(new Date());
      setBusyMessage('Finishing refresh...');
    } finally {
      setBusy(false);
    }
  };

  React.useEffect(() => {
    const storedMixnodes = window.sessionStorage.getItem(CACHE_KEY);
    if (storedMixnodes?.length) {
      try {
        setMixnodes(JSON.parse(storedMixnodes));
        setLastUpdated(new Date());
      } catch (_) {
        // do nothing
      }
    }

    refresh();
    const timer = setInterval(refresh, 1000 * 60 * 5);
    return () => clearInterval(timer);
  }, []);

  React.useEffect(() => {
    setSummary({
      termsAndConditions: {
        count: mixnodes.filter((node) => node.configScore?.acceptsTermsAndConditions === true).length,
        description: <>✅ Accepted operator terms & conditions</>,
      },
      latestNymNodes: {
        count: mixnodes.filter((node) => node.configScore?.minBuildVersion === true).length,
        description: (
          <>
            Runs <code>nym-node</code> &gt;= v{minNymNodeVersion}
          </>
        ),
      },
      nymNodes: {
        count: mixnodes.filter(
          (node) => node.configScore?.runsNymnode === true && node.configScore?.minBuildVersion === false,
        ).length,
        description: (
          <>
            Runs <code>nym-node</code>
          </>
        ),
      },
      unbonded: {
        count: mixnodes.filter((node) => !node.bonded).length,
        description: <>Unbonded nodes</>,
      },
    });
  }, [mixnodes]);

  const mixnodeVersions = React.useMemo(() => {
    const versions: any = {
      unknown: 0,
    };
    mixnodes
      .filter((g) => g.bonded)
      .map((g) => g.self_described?.self_described?.build_information?.build_version)
      .forEach((version) => {
        if (version) {
          if (!versions[version]) {
            versions[version] = 0;
          }
          versions[version] += 1;
        } else {
          versions.unknown += 1;
        }
      });
    return Object.keys(versions)
      .map((v) => ({ version: v, count: versions[v] }))
      .sort((a, b) => {
        if (a.version === 'unknown' || b.version === 'unknown') {
          return -1;
        }
        return semverCompare(a.version, b.version);
      });
  }, [mixnodes]);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: 'Mix Id',
        accessorKey: 'mix_id',
        Cell: ({ cell }) => (
          <Link component={RouterLink} to={`/mixnode/${cell.getValue<string>()}`}>
            {cell.getValue<string>()}
          </Link>
        ),
      },
      {
        header: 'Moniker',
        accessorKey: 'description.moniker',
      },
      {
        header: 'Identity Key',
        accessorKey: 'full_details.mixnode_details.bond_information.mix_node.identity_key',
        Cell: ({ cell }) => (
          <Link component={RouterLink} to={`/mixnode/${cell.row.original.mix_id}`}>
            {cell.getValue<string>().slice(0, 10)}...
          </Link>
        ),
      },
      {
        header: 'Owner Account',
        accessorKey: 'full_details.mixnode_details.bond_information.owner',
      },
      {
        header: 'Total Stake',
        accessorKey: 'total_stake',
        Cell: ({ cell }) => {
          const value = cell.getValue<Number | undefined | null>();
          if (!value) {
            return '-';
          }
          return (
            <Box textAlign="right" mr={2}>
              {(value.valueOf() / 1e6).toLocaleString('lookup', {
                style: 'decimal',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}{' '}
              NYM
            </Box>
          );
        },
      },
      // {
      //   header: 'Bonded',
      //   accessorKey: 'bonded',
      //   Cell: ({ cell }) => (cell.getValue<Boolean>() ? '✅' : '❌'),
      // },
      {
        header: 'Blacklisted',
        accessorKey: 'blacklisted',
        Cell: ({ cell }) => (cell.getValue<Boolean>() ? '🔴' : ''),
      },
      {
        header: 'DP Delegatee',
        accessorKey: 'is_dp_delegatee',
        Cell: ({ cell }) => (cell.getValue<Boolean>() ? '✅' : '❌'),
      },
      {
        header: 'Accepted T&Cs',
        accessorKey: 'self_described.self_described.auxiliary_details.accepted_operator_terms_and_conditions',
        Cell: ({ cell }) => (cell.getValue<Boolean>() ? '✅' : '❌'),
      },
      {
        header: 'Performance',
        accessorKey: 'full_details.performance',
      },
      {
        header: 'Stake Saturation',
        accessorKey: 'full_details.stake_saturation',
      },
      {
        header: 'Software Version',
        accessorKey: 'self_described.self_described.build_information.build_version',
      },
      {
        header: 'Git Commit Hash',
        accessorKey: 'self_described.self_described.build_information.commit_sha',
        Cell: ({ cell }) => <code>{cell.getValue<string>()?.slice(0, 6)}</code>,
      },
      {
        header: 'Last Updated',
        accessorKey: 'last_updated_utc',
        Cell: ({ cell }) => <HumanDateTime value={cell.getValue<any>()} />,
      },
      // {
      //   header: 'Name',
      //   accessorKey: 'description.name',
      // }, {
      //   header: 'Description',
      //   accessorKey: 'description.description',
      // }
    ],
    [],
  );
  const table = useMaterialReactTable({
    columns,
    initialState: {
      pagination: { pageSize: 25, pageIndex: 0 },
      density: 'compact',
      showColumnFilters: true,
      sorting: [
        {
          id: 'total_stake',
          desc: true,
        },
      ],
    },
    data: mixnodes,
    enableRowSelection: true, // enable some features
    enableColumnOrdering: false, // enable a feature for all columns
    enableGlobalFilter: false, // turn off a feature
  });

  if (!lastUpdated) {
    return (
      <Box mt={4} display="flex" alignItems="center">
        <CircularProgress />
        <Typography fontSize="larger" ml={2}>
          Please wait while gathering rainbows and sorting kittens... 🌈😼
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box mt={5} py={2}>
        <Box mt={2} pb={4}>
          <Stack direction="row" spacing={4}>
            <Box>
              <Typography fontWeight={600}>Config scores</Typography>
              <Table>
                <TableBody>
                  <SummaryLine
                    emoji={
                      <Badge
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={<Typography fontSize={30}>😀</Typography>}
                        overlap="circular"
                      >
                        🐼
                      </Badge>
                    }
                    item={summary.termsAndConditions}
                    total={mixnodes.length}
                  />
                  <SummaryLine emoji="🐼️" item={summary.latestNymNodes} total={mixnodes.length} />
                  <SummaryLine emoji="🐬" item={summary.nymNodes} total={mixnodes.length} />
                  <SummaryLine emoji="☠️" item={summary.unbonded} total={mixnodes.length} hidePercentage />
                </TableBody>
              </Table>
            </Box>
          </Stack>
        </Box>
        {mixnodeVersions.length && (
          <>
            <Typography fontWeight={600} mt={3}>
              Software versions
            </Typography>
            <BarChart
              dataset={mixnodeVersions}
              xAxis={[{ scaleType: 'band', dataKey: 'version', label: 'Versions' }]}
              series={[{ dataKey: 'count' }]}
              width={500}
              height={300}
              yAxis={[{ label: 'Mixnodes' }]}
            />
          </>
        )}

        <Box display="flex" justifyContent="space-between">
          <Box>
            {busy ? (
              <Typography>{busyMessage || 'Refreshing...'}</Typography>
            ) : (
              <Button variant="outlined" onClick={refresh}>
                Refresh
              </Button>
            )}
          </Box>
          {lastUpdated && (
            <Stack direction="row" spacing={2}>
              <Typography>Last updated</Typography>
              <Typography>
                <strong>
                  {formatDistanceToNow(lastUpdated, {
                    addSuffix: true,
                  })}
                </strong>
                <br />
                {lastUpdated.toISOString()}
              </Typography>
            </Stack>
          )}
        </Box>
        <MaterialReactTable table={table} />
      </Box>
    </Box>
  );
};
