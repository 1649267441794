import { gte, parse, coerce } from 'semver';
import { GatewayConfigScore, GatewayConfigScoreAndValue } from '../types';
import { minNymNodeVersion } from '../scores';

export const testSemVer = (version?: string) => {
  if (!version) {
    return false;
  }
  const requiredVersionParsed = parse(minNymNodeVersion);
  const versionParsed = parse(version);
  if (!requiredVersionParsed || !versionParsed) {
    return false;
  }

  const coercedRequiredVersion = coerce(minNymNodeVersion)?.toString();
  if (!coercedRequiredVersion) {
    return false;
  }

  if (
    coercedRequiredVersion === version &&
    requiredVersionParsed.prerelease.length &&
    !versionParsed.prerelease.length
  ) {
    return false;
  }

  return gte(version, minNymNodeVersion);
};
export const getConfigScore = (item: any): GatewayConfigScoreAndValue => {
  const version = item.self_described?.build_information?.build_version || '0.0.0';
  const binaryName = item.self_described?.build_information?.binary_name;

  const configScoreItems: GatewayConfigScore = {
    runsEntryGateway: Boolean(item.explorer_pretty_bond?.gateway?.identity_key),
    runsNymnode: binaryName === 'nym-node',
    minBuildVersion: testSemVer(version) && binaryName === 'nym-node',
    runsIPR: Boolean(item.self_described?.ip_packet_router?.address),
    runsNetworkRequester: Boolean(item.self_described?.network_requester?.address),
    usesExitPolicy: Boolean(item.self_described?.network_requester?.uses_exit_policy) !== false,
    setLocation: Boolean(item.self_described?.auxiliary_details?.location),
    runsAuthenticatorServiceProvider: Boolean(item.self_described?.authenticator?.address?.length),
  };

  let configScore = 0;

  if (configScoreItems.minBuildVersion) {
    configScore += 100;
  }
  if (configScoreItems.runsNymnode) {
    configScore += 100;
  }
  if (configScoreItems.runsIPR) {
    configScore += 100;
  }
  if (configScoreItems.runsNetworkRequester) {
    configScore += 100;
  }
  if (configScoreItems.usesExitPolicy) {
    configScore += 100;
  }
  if (configScoreItems.runsAuthenticatorServiceProvider) {
    configScore += 100;
  }

  return {
    configScoreItems,
    configScore,
  };
};
