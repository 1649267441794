/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { MaterialReactTable, type MRT_ColumnDef, useMaterialReactTable } from 'material-react-table';
import Typography from '@mui/material/Typography';
import { contracts } from '@nymproject/contract-clients';
import { CosmWasmClient } from '@cosmjs/cosmwasm-stargate';
import Alert from '@mui/material/Alert';
import { flatten } from 'flat';

const RPC_URL = 'https://rpc.cosmos.directory/nyx';

interface PagedResponse {
  nodes: any[];
  start_next_after?: any;
}

type PagedQueryFn = ({ limit, startAfter }: { limit?: number | undefined; startAfter?: any }) => Promise<PagedResponse>;

export const ContractExplorer: React.FC = () => {
  const [lastUpdated, setLastUpdated] = React.useState<Date>();
  const [busy, setBusy] = React.useState(false);
  const [busyMessage, setBusyMessage] = React.useState<string>();
  const [error, setError] = React.useState<string>();
  const [data, setData] = React.useState<any[]>([]);
  const [flat, setFlat] = React.useState<string[]>([]);

  const createClient = async () => {
    const cosmWasmClient = await CosmWasmClient.connect(RPC_URL);
    const client = new contracts.Mixnet.MixnetQueryClient(
      cosmWasmClient,
      'n17srjznxl9dvzdkpwpw24gg668wc73val88a6m5ajg6ankwvz9wtst0cznr',
    );
    return client;
  };

  const doQueryPaged = async (fn: PagedQueryFn) => {
    const output = [];
    const limit = 50;
    let startAfter;
    let pages = 0;
    do {
      // eslint-disable-next-line no-await-in-loop
      const res: any = await fn({ limit, startAfter });
      output.push(...res.nodes);
      if (startAfter === res.start_next_after) {
        break;
      }
      startAfter = res.start_next_after;
      pages += 1;
    } while (pages < 20 && startAfter !== undefined && startAfter !== null);
    return output;
  };

  const doQueryNymNodes = async () => {
    const client = await createClient();
    return doQueryPaged(client.getNymNodesDetailedPaged);
  };

  const doQueryLegacyGateways = async () => {
    const client = await createClient();
    return doQueryPaged(client.getGateways);
  };

  const doQueryLegacyMixnodes = async () => {
    const client = await createClient();
    return doQueryPaged(client.getMixNodeBonds);
  };

  const doQueryUnbondedLegacyMixnodes = async () => {
    const client = await createClient();
    return doQueryPaged(client.getUnbondedMixNodes);
  };

  const doQueryUnbondedNymNodes = async () => {
    const client = await createClient();
    return doQueryPaged(client.getUnbondedNymNodesPaged);
  };

  const runQuery = async (fn: () => Promise<any>) => {
    try {
      setError(undefined);
      setBusy(true);
      setBusyMessage('Getting latest data...');

      const res = await fn();
      const flattened = res.map((item: any) => flatten(item));
      setData(res);
      setFlat(Object.keys(flattened[0] || {}));

      setLastUpdated(new Date());
      setBusyMessage('Finishing refresh...');

      return res;
    } catch (e: any) {
      setError(e.message);
    } finally {
      setBusy(false);
    }
    return undefined;
  };

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () =>
      flat.map((key) => {
        const col: MRT_ColumnDef<any> = {
          header: key,
          accessorKey: key,
          Cell: ({ cell }) => <pre>{JSON.stringify(cell.getValue(), null, 2)}</pre>,
        };
        return col;
      }),
    [data, flat],
  );

  const table = useMaterialReactTable({
    columns,
    initialState: {
      pagination: { pageSize: 25, pageIndex: 0 },
      density: 'compact',
      showColumnFilters: true,
      sorting: [
        {
          id: 'moniker',
          desc: true,
        },
      ],
    },
    data,
    enableColumnOrdering: false,
    enableGlobalFilter: true,
  });

  return (
    <Box>
      <Box my={2}>
        <Typography>Query the Nym mixnet contract</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box>
          {error && <Alert severity="error">{error}</Alert>}
          {busy ? (
            <Typography>{busyMessage || 'Refreshing...'}</Typography>
          ) : (
            <Stack direction="row" spacing={1}>
              <Button variant="outlined" onClick={async () => runQuery(doQueryNymNodes)}>
                Query Nym Nodes
              </Button>
              <Button variant="outlined" onClick={async () => runQuery(doQueryLegacyMixnodes)}>
                Query Legacy Mixnodes
              </Button>
              <Button variant="outlined" onClick={async () => runQuery(doQueryLegacyGateways)}>
                Query Legacy Gateways
              </Button>
              <Button variant="outlined" onClick={async () => runQuery(doQueryUnbondedLegacyMixnodes)}>
                Query Unbonded Legacy Mixnodes
              </Button>
              <Button variant="outlined" onClick={async () => runQuery(doQueryUnbondedNymNodes)}>
                Query Unbonded Nym Nodes
              </Button>
            </Stack>
          )}
        </Box>
        {lastUpdated && (
          <Stack direction="row" spacing={2}>
            <Typography>Last updated</Typography>
            <Typography>
              <strong>
                {formatDistanceToNow(lastUpdated, {
                  addSuffix: true,
                })}
              </strong>
              <br />
              {lastUpdated.toISOString()}
            </Typography>
          </Stack>
        )}
      </Box>
      {data?.length > 0 && <MaterialReactTable table={table} />}
    </Box>
  );
};
