import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import Box from '@mui/material/Box';

export const MixnetStatsCharts: React.FC<{ mixnetStats: any }> = ({ mixnetStats }) => {
  const xLabels = React.useMemo(() => {
    if (!mixnetStats) {
      return [];
    }
    return mixnetStats.map((item: any) => item.date_utc);
  }, [mixnetStats]);

  const series = React.useMemo(() => {
    if (!mixnetStats) {
      return [];
    }
    const totalPacketsReceived = mixnetStats.map((item: any) => item.total_packets_received);

    const totalPacketsSent = mixnetStats.map((item: any) => item.total_packets_sent);
    const totalPacketsDropped = mixnetStats.map((item: any) => item.total_packets_dropped);

    return [
      {
        data: totalPacketsReceived,
        label: 'Total Packets Received',
        yAxisId: 'packets',
      },
      {
        data: totalPacketsSent,
        label: 'Total Packets Sent',
        yAxisId: 'packets',
      },
      {
        data: totalPacketsDropped,
        label: 'Total Packets Dropped',
        yAxisId: 'packets',
      },
    ];
  }, [mixnetStats]);

  return (
    <Box sx={{ width: '100%', maxWidth: 1200 }}>
      <LineChart
        height={500}
        series={series}
        xAxis={[{ scaleType: 'point', data: xLabels }]}
        slotProps={{
          legend: {
            direction: 'row',
            position: { vertical: 'top', horizontal: 'middle' },
            padding: 0,
          },
        }}
        margin={{
          left: 100,
          right: 100,
          top: 80,
          bottom: 80,
        }}
      />
    </Box>
  );
};

export const MixnetStakingCharts: React.FC<{ mixnetStats: any; height: number }> = ({ mixnetStats, height }) => {
  const xLabels = React.useMemo(() => {
    if (!mixnetStats) {
      return [];
    }
    return mixnetStats.map((item: any) => item.date_utc);
  }, [mixnetStats]);

  const series = React.useMemo(() => {
    if (!mixnetStats) {
      return [];
    }
    const totalStakeNyms = mixnetStats.map((item: any) => item.total_stake / 1e6);

    return [
      {
        data: totalStakeNyms,
        label: 'Total Mixnode Delegations (NYM)',
        yAxisId: 'tokens',
      },
    ];
  }, [mixnetStats]);

  return (
    <Box sx={{ width: '100%', maxWidth: 1200 }}>
      <LineChart
        height={height}
        series={series}
        xAxis={[{ scaleType: 'point', data: xLabels }]}
        slotProps={{
          legend: {
            direction: 'row',
            position: { vertical: 'top', horizontal: 'middle' },
            padding: 0,
          },
        }}
        margin={{
          left: 100,
          right: 100,
          top: 80,
          bottom: 80,
        }}
      />
    </Box>
  );
};
