import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Home } from './Home';
import { Gateway } from './Gateway';
import { Mixnode } from './Mixnode';
import { GatewayPage } from './GatewayTestPage';

const NoMatch: React.FC = () => <Box p={4}>Oops, sorry not found</Box>;

export const App: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route index element={<Home />} />
      <Route path="gateway/:gatewayIdentityKey" element={<Gateway />} />
      <Route path="gateway/:gatewayIdentityKey/test-results" element={<GatewayPage />} />
      <Route path="mixnode/:mixid" element={<Mixnode />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  </BrowserRouter>
);
