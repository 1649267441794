import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Flag from 'react-world-flags';

export const SummaryLine: React.FC<{ emoji: React.ReactNode; item: any; total: number; hidePercentage?: boolean }> = ({
  emoji,
  item,
  total,
  hidePercentage,
}) => (
  <TableRow>
    <TableCell>
      <Typography fontSize="xx-large">{emoji}</Typography>
    </TableCell>
    <TableCell>
      <Typography fontSize="larger" fontWeight={600}>
        {item.count}
      </Typography>
    </TableCell>
    <TableCell>
      {!hidePercentage && <Typography fontSize="larger">{Math.round((item.count * 100) / total)}%</Typography>}
    </TableCell>
    <TableCell>{item.description || ''}</TableCell>
    <TableCell sx={{ maxWidth: 300 }}>
      {item.countries &&
        Object.keys(item.countries)
          .map((key) => ({ key, value: item.countries[key] }))
          .sort((a, b) => b.value - a.value)
          .map(({ key, value }) => (
            <>
              <Chip label={value} size="small" icon={<Flag code={key} width={16} />} />{' '}
            </>
          ))}
    </TableCell>
  </TableRow>
);
