/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { MaterialReactTable, type MRT_ColumnDef, useMaterialReactTable } from 'material-react-table';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import { api } from './api';
import { HumanDateTime } from './HumanDateTime';

export const NodeSearch: React.FC = () => {
  const [mixnodes, setMixnodes] = React.useState<any[]>([]);
  const [gateways, setGateways] = React.useState<any[]>([]);
  const [lastUpdated, setLastUpdated] = React.useState<Date>();
  const [busy, setBusy] = React.useState(false);
  const [busyMessage, setBusyMessage] = React.useState<string>();
  const [searchTerms, setSearchTerms] = React.useState<string>();

  const refreshMixnodes = async () => {
    try {
      const res = await api.mixnodes();
      setMixnodes(res);
      setBusyMessage('Mixnode data updated...');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error getting services', e);
    }
  };
  const refreshGateways = async () => {
    try {
      const res = await api.gateways();
      setGateways(res);
      setBusyMessage('Gateway data updated...');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error getting services', e);
    }
  };

  const refresh = async () => {
    try {
      setBusy(true);
      setBusyMessage('Getting latest data...');
      await Promise.all([refreshMixnodes(), refreshGateways()]);
      setLastUpdated(new Date());
      setBusyMessage('Finishing refresh...');
    } finally {
      setBusy(false);
    }
  };

  React.useEffect(() => {
    refresh();
    const timer = setInterval(refresh, 1000 * 60 * 5);
    return () => clearInterval(timer);
  }, []);

  const rawItems = React.useMemo(
    () =>
      [
        ...mixnodes.map((node: any) => {
          console.log(node);
          return {
            kind: 'mixnode',
            identityKey: node.full_details.mixnode_details.bond_information.mix_node.identity_key,
            owner: node.full_details.mixnode_details.bond_information.owner,
            node,
          };
        }),
        ...gateways.map((node: any) => {
          console.log(node);
          return {
            kind: 'gateway',
            identityKey: node.gateway_identity_key,
            owner: node.explorer_pretty_bond.owner,
            node,
          };
        }),
      ].sort((a, b) => a.node.description?.moniker.localeCompare(b.node.description?.moniker)),
    [mixnodes, gateways],
  );

  const items = React.useMemo(() => {
    if (!searchTerms?.length) {
      return rawItems;
    }
    const terms = searchTerms.split(/[\s+,]/).map((term) => term.trim());
    return rawItems.filter((node) => terms.includes(node.owner) || terms.includes(node.identityKey));
  }, [rawItems, searchTerms]);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: 'Kind',
        accessorKey: 'kind',
      },
      {
        header: 'Identity',
        accessorKey: 'identityKey',
        Cell: ({ cell }) => {
          if (cell.row.original.kind === 'mixnode') {
            return (
              <Link component={RouterLink} to={`/mixnode/${cell.getValue<string>()}`}>
                {cell.getValue<string>()}
              </Link>
            );
          }
          return (
            <Link component={RouterLink} to={`/gateway/${cell.getValue<string>()}`}>
              {cell.getValue<string>()}
            </Link>
          );
        },
      },
      {
        id: 'moniker',
        header: 'Moniker',
        accessorKey: 'node.description.moniker',
      },
      {
        header: 'Owner Account',
        accessorKey: 'owner',
      },
      {
        header: 'Last Updated',
        accessorKey: 'node.last_updated_utc',
        Cell: ({ cell }) => <HumanDateTime value={cell.getValue<any>()} />,
      },
    ],
    [],
  );
  const table = useMaterialReactTable({
    columns,
    initialState: {
      pagination: { pageSize: 25, pageIndex: 0 },
      density: 'compact',
      showColumnFilters: true,
      sorting: [
        {
          id: 'moniker',
          desc: true,
        },
      ],
    },
    data: items,
    enableRowSelection: true, // enable some features
    enableColumnOrdering: false, // enable a feature for all columns
    enableGlobalFilter: false, // turn off a feature
  });

  if (!lastUpdated) {
    return (
      <Box mt={4} display="flex" alignItems="center">
        <CircularProgress />
        <Typography fontSize="larger" ml={2}>
          Please wait while gathering rainbows and sorting kittens... 🌈😼
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box my={2}>
        <Typography>Search for identity keys or owner accounts:</Typography>
        <TextField
          multiline
          fullWidth
          rows={4}
          placeholder="Enter a list of search items"
          value={searchTerms}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchTerms(event.target.value);
          }}
        />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box>
          {busy ? (
            <Typography>{busyMessage || 'Refreshing...'}</Typography>
          ) : (
            <Button variant="outlined" onClick={refresh}>
              Refresh
            </Button>
          )}
        </Box>
        {lastUpdated && (
          <Stack direction="row" spacing={2}>
            <Typography>Last updated</Typography>
            <Typography>
              <strong>
                {formatDistanceToNow(lastUpdated, {
                  addSuffix: true,
                })}
              </strong>
              <br />
              {lastUpdated.toISOString()}
            </Typography>
          </Stack>
        )}
      </Box>
      <MaterialReactTable table={table} />
    </Box>
  );
};
