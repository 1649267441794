import React from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { HabourMasterHeader } from './Logo';
import { GatewayTestResults } from './GatewayTestResults';

export const GatewayPage: React.FC = () => {
  const { gatewayIdentityKey } = useParams();

  if (!gatewayIdentityKey) {
    return (
      <Box p={4}>
        <HabourMasterHeader />
        {gatewayIdentityKey && (
          <Link component={RouterLink} to={`/gateway/${gatewayIdentityKey}`}>
            <h2>
              ⛩️ Gateway <code>{gatewayIdentityKey}</code>
            </h2>
          </Link>
        )}
        <Box mt={4} display="flex" alignItems="center">
          <CircularProgress />
          <Typography fontSize="larger" ml={2}>
            Please have some popcorn 🍿 or peanuts 🥜 while we cook your order...
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box p={4}>
      <HabourMasterHeader />
      <Link component={RouterLink} to={`/gateway/${gatewayIdentityKey}`}>
        <h2>
          ⛩️ Gateway <code>{gatewayIdentityKey}</code>
        </h2>
      </Link>
      <GatewayTestResults identityKey={gatewayIdentityKey} />
    </Box>
  );
};
