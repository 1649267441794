import React from 'react';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

export const HumanDateTime: React.FC<{ value: any }> = ({ value }) => {
  if (!value) {
    return null;
  }
  let dateValue: Date | null = null;

  if (typeof value === 'string') {
    dateValue = new Date(value);
  } else if (Object.prototype.toString.call(value) === '[object Date]') {
    dateValue = value as Date;
  }
  if (!dateValue) {
    return null;
  }

  return (
    <>
      {dateValue.toISOString()}
      <br />
      {formatDistanceToNow(dateValue, { addSuffix: true })}
    </>
  );
};
