import { GatewayProbeScoreAndValue } from '../types';

export const getProbeScore = (item: any): GatewayProbeScoreAndValue => {
  let probeScore = 0;
  const probeScoreItems = item.last_probe_result?.outcome;

  if (probeScoreItems?.as_entry?.can_connect) {
    probeScore += 100;
  }
  if (probeScoreItems?.as_entry?.can_route) {
    probeScore += 100;
  }
  if (probeScoreItems?.as_exit?.can_connect) {
    probeScore += 100;
  }
  if (probeScoreItems?.as_exit?.can_route_ip_v4) {
    probeScore += 100;
  }
  if (probeScoreItems?.as_exit?.can_route_ip_v6) {
    probeScore += 100;
  }
  if (probeScoreItems?.as_exit?.can_route_ip_external_v4) {
    probeScore += 100;
  }
  if (probeScoreItems?.as_exit?.can_route_ip_external_v6) {
    probeScore += 100;
  }
  if (probeScoreItems?.wg?.can_handshake) {
    probeScore += 100;
  }
  if (probeScoreItems?.wg?.can_register) {
    probeScore += 100;
  }
  if (probeScoreItems?.wg?.can_resolve_dns) {
    probeScore += 100;
  }
  if (probeScoreItems?.wg?.ping_hosts_performance > 0.75) {
    probeScore += 100;
  }
  if (probeScoreItems?.wg?.ping_ips_performance > 0.75) {
    probeScore += 100;
  }

  return {
    probeScore,
    probeScoreItems,
  };
};
